import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'src/js/slick.js';
import 'src/js/function.js';
// import だと jQuery is not defined になる
require('jquery-lazyload');
import Stickyfill from 'stickyfill';
import 'jquery-match-height';
import slider_arrow_prev from 'images/slider_arrow_prev.svg';
import slider_arrow_next from 'images/slider_arrow_next.svg';

import '../_sass/app.scss';
import '../css/slick.css';

require.context('../images/', true);

import Rails from 'rails-ujs';
Rails.start();
jQuery.rails = Rails;
jQuery.ajaxPrefilter(function(options, originalOptions, xhr) {
  if (!options.crossDomain) {
    return Rails.CSRFProtection(xhr);
  }
});

$(function() {
  $('.c-slider').slick({
    autoplay: true,
    arrows: true,
    prevArrow: `<a class="slick-prev" href="#"><img src="${slider_arrow_prev}"></a>`,
    nextArrow: `<a class="slick-next" href="#"><img src="${slider_arrow_next}"></a>`,
    dots: true,
    variableWidth: true,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: 0,
          variableWidth: false,
          slidesToShow: 1
        }
      }
    ]
  });

  try {
    Stickyfill().add(document.querySelectorAll('.sticky'));
  } catch (error) {
    // works in IE9+ のはずが IE11 だとエラーになるので
    console.log(error);
  }

  $('.js-matchHeight h3').matchHeight();
  $('.js-matchHeigh aside').matchHeight();
  $('li.c-people__index__li').matchHeight();

  $("img.lazy").lazyload({
    effect: 'fadeIn',
    effectspeed: 300
  });

  // リニューアル前の機能
  $(document).on('click', '.checklist-calendar__title-wrapper', function() {
    $(this).parent().find("[data-swichable]").slideToggle(200);
    return false;
  });
  $(document).on('click', "[data-all-opened-link]", function() {
    $("[data-swichable]").show(200);
    return false;
  });
  $(document).on('click', "[data-all-closed-link]", function() {
    $("[data-swichable]").hide(200);
    return false;
  });
  $(document).on('click', '[data-delete-bookmark]', function(event) {
    event.preventDefault();
    var $this = $(this)
    $.post('/bookmarks/' + $this.data('delete-bookmark') + '.js',
           { _method: 'delete' });
    $this.closest('.js-wrap').hide('drop');
  });

  if ($(window).width() > 640) {
    $('#search[hidden]').removeAttr('hidden');
  }
});
