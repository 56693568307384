import $ from 'jquery';
import objectFitImages from 'object-fit-images';

$(function() {
  var BREAK = 768;
  var $window;
  var winW;
  $window = $(window);
  winW = $window.width();

  scroll();

  if($window.width() >= BREAK){
    // ナビゲーション
    const current = $('.l-header__nav__li > a.is-current');
    const nav = $('.l-header__nav__li > a');
    nav.hover(function(){
      nav.removeClass('is-current');
      if($(this).hasClass('js-subNav')){
        $('.l-header__subNav').hide();
        const subNav = $(this).attr('data-category');
        $('.' + subNav).show();
      }else{
        $('.l-header__subNav').hide();
      }
    });

    var timer = undefined;
    // ヘッダーから外れたら、再度カレント表示に戻す
    $(document).on('mouseleave', '#menu' ,function() {
      timer = setTimeout(function() {
        current.addClass('is-current');
        $('.l-header__subNav').hide();
      }, 500);
    });
    $(document).on('mouseenter', '#menu' ,function() {
      if (timer) {
        clearTimeout(timer);
        timer = undefined;
      }
    });
  }

  // スムーススクロール
  var anchor = (function(){
    $('.js-scroll').on('click',function(){
      var target = $($(this).attr('href')).offset().top;
      scroll(target);
      return false;
    });
  })();

  // スクロール
  function scroll(target){
    $('html , body').animate({
      scrollTop: target
    },400);
  }

  // pageTopボタン
  $(window).scroll(function(){
    var pos = $(window).scrollTop();
    if(pos > 200){
      $('.l-footer__pageTop').addClass('is-show');
    }else{
      $('.l-footer__pageTop').removeClass('is-show');
    }
  });

  objectFitImages('img.u-ojf');
});
